<template>
    <topBanner/>
    <aboutUs/>
</template>

<script>
import topBanner from '../components/common/topBanner.vue'
import aboutUs from '../components/about.vue'
export default {
    components:{
        aboutUs,
        topBanner
    },
    data(){
        return{}
    }
}
</script>